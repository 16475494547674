import {datadogRum} from '@datadog/browser-rum';
const devMode = import.meta.env.DEV;

/**
 * Universal error handler.
 * Logs error to console and sends it to Datadog.
 * `context` is an optional object. Its properties can be used to filter the logs in Datadog.
 *
 * @param error - The error to log
 * @param context - Additional context to send to Datadog
 * @example
 * ```ts
 * try {
 *  throw new Error('This is an error');
 * } catch (error) {
 * logError(error, {additional: 'context'});
 * }
 * ```
 */
export function logError(
  error: unknown,
  context: Record<string, unknown> = {}
): void {
  if (devMode) {
    // eslint-disable-next-line no-console
    console.error(error, context);
  }

  try {
    datadogRum.addError(error as Error, context);
  } catch (datadogError) {
    // eslint-disable-next-line no-console
    console.error(datadogError);
  }
}

/**
 * Logs an informational message and sends it to Datadog.
 * `context` is an optional object. Its properties can be used to filter the logs in Datadog.
 *
 * @param message - The message to be logged.
 * @param context - Additional context information to be sent to Datadog. Defaults to an empty object.
 * @example
 * ```ts
 * logInfo('SomethingImportantHappened', {additional: 'context'});
 * ```
 */
export function logInfo(
  message: string,
  context: Record<string, unknown> = {}
): void {
  if (devMode) {
    // eslint-disable-next-line no-console
    console.debug(message, context);
  }

  try {
    datadogRum.addAction(message, context);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
}
